import React from 'react';
import { styled } from '@mui/material';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Container = styled('div')`
  border-radius: 10px;
  padding: 0 24px;
  margin-left: 70px;
  margin-right: 70px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: 0;
  }
`;

const Error: React.FC = () => (
  <Layout>
    <SEO title="HP Dev One | 404 Error" description="404 Error" />
    <Container>
      <h1>Error 404: Not Found</h1>
      <h2>
        Hp Dev One has been discontinued visit{' '}
        <a href="/" style={{ textDecoration: 'underline' }}>
          Home
        </a>{' '}
        for more information
      </h2>
    </Container>
  </Layout>
);

export default Error;
